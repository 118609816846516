/* Coded with love by Mutiullah Samim */

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  padding-right: 0px !important;
  background: #eee !important;
}

#container {
  min-height: 100%;
  position: relative;
}

#main-content {
  padding-bottom: 60px;
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.brand_logo {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 2px solid white;
}

.bagger,
.logout {
  background-color: transparent !important;
}

.bagger:focus,
.logout:focus {
  box-shadow: none !important;
}

.logout {
  color: brown;
}

.logout:active {
  color: red;
}

.bagger:active {
  color: blue;
}

#side-drawer {
  height: 100%;
  width: 240px;
  z-index: 1032;
  top: 0;
  left: -240px;
  transition: left 0.25s ease;
  background-color: #fff;
}

#side-drawer.active {
  left: 0;
}

#side-drawer-void {
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1031;
  background-color: rgba(0, 0, 0, 0.5);
}

.nav-icon {
  cursor: pointer;
  user-select: none;
}

.photo-icon {
  color: #000;
  user-select: none;
}

.nav-icon:active,
.photo-icon:active {
  color: #b9b9b9;
}

.sidebar-user {
  /* background: #fff; */
  color: #153d77;
  padding: 1.5rem 1rem 1rem;
  text-align: center;
}

.sidebar-user img {
  height: 64px;
  width: 64px;
}

.header-nav img {
  height: 32px;
  width: 32px;
}

.sidebar-nav {
  flex: 1;
  justify-content: flex-start;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.sidebar-link,
a.sidebar-link {
  background: transparent;
  border-radius: 0.2rem;
  color: #6c757d;
  cursor: pointer;
  display: block;
  font-weight: 400;
  margin: 0 0.5rem;
  padding: 0.65rem 0.75rem;
  position: relative;
  text-decoration: none;
}

.sidebar-item.active .sidebar-link:hover,
.sidebar-item.active > .sidebar-link {
  background-color: #e9ecef;
  color: #212529;
}

/* .header-nav,
#side-drawer.active {
   box-shadow: 0 6px 6px -3px rgb(0 0 0 / 20%), 0 10px 14px 1px rgb(0 0 0 / 14%),
    0 4px 18px 3px rgb(0 0 0 / 12%); 
} */

.sidebar-link i,
.sidebar-link span {
  color: #17a2b8;
}

.login-header,
.app-bar {
  background-color: #0f7197 !important;
  /* box-shadow: 0px 6px 10px -5px #111; */
  color: white;
}

.header-nav,
.sidebar-user,
.profile {
  background-color: rgb(15, 113, 151);
  color: white;
}

.time-table-header {
  background-color: rgb(15, 113, 151);
  color: white;
  padding: 5px;
  /* border: 1px solid rgb(15, 113, 151); */
  /* border-top: 1px solid white; */
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  font-size: 2vw;
  user-select: none;
}

/* .time-table-header.period {
   border-left: 1px solid rgb(15, 113, 151);
} */

.time-table-body {
  background-color: #eee;
  color: rgb(15, 113, 151);
  padding: 5px;
  border-bottom: 1px solid rgb(15, 113, 151);
  border-right: 1px solid rgb(15, 113, 151);
  font-size: 1.5vw;
  display: flex;
  /* flex-wrap: nowrap; */
  flex-direction: column;
  align-content: center;
  align-items: center;
  user-select: none;
}

/* .time-table-body:hover {
  cursor: pointer;
  background-color: #fff;
  color: rgb(15, 113, 151);
} */

/* .time-table-body:hover .teacher-name {
   border: 1px solid rgb(15, 113, 151); 
} */

.teacher-name {
  font-size: 1vw;
  /* background-color: rgb(55, 104, 145); */
  border-radius: 10px;
  padding: 2px;
  color: #000;
  /* border: 1px solid cornsilk; */
  user-select: none;
}

.time-table-body-period {
  color: #000;
  padding: 5px;
  /* border: 1px solid rgb(15, 113, 151); */
  /* border-top: 1px solid rgb(15, 113, 151); */
  border-bottom: 1px solid rgb(15, 113, 151);
  border-left: 1px solid rgb(15, 113, 151);
  border-right: 1px solid rgb(15, 113, 151);
  font-size: 1.5vw;
  display: flex;
  flex-direction: column;
  background-color: #e0f4f3;
  user-select: none;
}

button:focus {
  outline: none !important;
}

.level-card {
  min-height: 25vh;
  padding-bottom: 18px;
}

/* .level-card .scroll-y {
  height: 52vh;
  overflow-y: scroll !important;
} */

.close-btn {
  position: absolute !important;
  right: 0 !important;
  /* top: 0 !important; */
  /* color: red !important; */
}

/* button:disabled {
  background-color: rgb(218 211 211 / 48%) !important;
} */

.fab-container {
  position: fixed;
  /* width: 50px; */
  /* height: 50px; */
  bottom: 0;
  right: 0;
  /* margin: 35px 25px; */
}
button.outlined.active {
  background-color: rgb(15 113 151);
  color: white;
}

.fab-container .fab-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0;
  min-width: 0;
  width: 65px !important;
  height: 65px !important;
  background-color: #eee;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-primary,
.MuiButton-containedPrimary,
.MuiFab-primary {
  background-color: #0f7197 !important;
  color: white !important;
}

.break-period {
  background-color: rgb(249, 250, 187);
}

.lunch-period {
  background-color: rgb(237, 231, 117);
}

.MuiButton-containedPrimary:disabled,
.MuiFab-primary:disabled {
  background-color: rgb(189 189 189) !important;
}

input:read-only {
  background-color: #eee !important;
}

.bg-pending {
  background-color: #e9eafc !important;
}
